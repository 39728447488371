import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Creativity from "../../../images/ruby/Creativity.svg"
import * as styles from "./Services.module.scss"

const Services = ({ strapiData }) => {
  return (
    <div className={`${styles.service} ${styles.head}`}>
      <div className={styles.Creativity}>
        <img
          src={Creativity}
          decoding="async"
          loading="lazy"
          alt="Creativity"
        />
      </div>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <Row className="gap-30">
          {strapiData?.cards &&
            strapiData?.cards?.map((e, index) => {
              return (
                <Col
                  lg={6}
                  md={6}
                  xs={12}
                  key={index}
                  style={{ display: "flex" }}
                >
                  <div className={styles.box}>
                    <h3>{e?.title}</h3>{" "}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.description?.description,
                      }}
                    />
                  </div>
                </Col>
              )
            })}
        </Row>
      </Container>
    </div>
  )
}

export default Services
