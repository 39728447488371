import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import findRec from "../../../images/ruby/findRec.svg"
import findPlus from "../../../images/ruby/findPlus.svg"
import findRec2 from "../../../images/ruby/findRec2.svg"
import findCir from "../../../images/ruby/findCir.svg"
import * as styles from "./Skillset.module.scss"

const Skillset = ({ strapiData }) => {
  return (
    <div className={styles.subIntro}>
      <div className={styles.findPlus}>
        <img src={findPlus} decoding="async" loading="lazy" alt="plus" />
      </div>
      <div className={styles.findRec2}>
        <img src={findRec2} decoding="async" loading="lazy" alt="reactangle" />
      </div>
      <Container>
        <div className={styles.findRec}>
          <img src={findRec} decoding="async" loading="lazy" alt="reactangle" />
        </div>
        <div className={styles.findCir}>
          <img src={findCir} decoding="async" loading="lazy" alt="circle" />
        </div>
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.description?.description,
          }}
        />
        <Row className="gap-50 mt-5">
          {strapiData?.cards &&
            strapiData?.cards.map((e, i) => (
              <Col xs={12} md={6} lg={4} key={i}>
                <div className={styles.card}>
                  {e?.image1 && (
                    <img
                      decoding="async"
                      loading="lazy"
                      src={e?.image1[0]?.localFile?.publicURL}
                      alt={e?.title}
                    />
                  )}
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.description?.description,
                    }}
                  />
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default Skillset
