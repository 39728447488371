import { graphql } from "gatsby"
import React from "react"
import TextBtnStrip2 from "../components/common/Curious2"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import Benefits from "../components/common/nodejs/Benefits"
import Budget from "../components/common/ruby/Budget"
import RubbySummary from "../components/common/ruby/RubbySummary"
import Services from "../components/common/ruby/Services"
import Skillset from "../components/common/ruby/Skillset"
import Talent from "../components/common/ruby/Talent"
import Banner2 from "../components/services2/Banner2"
import simage2 from "../images/ruby/last2.svg"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/hire_ruby_on_rails_developer_7ef97d0619.webp"
    />
  )
}
const Ruby = ({ data }) => {
  const banner = data?.strapiPage?.sections[0]
  const handPicked = data?.strapiPage?.sections[1]
  const skillSet = data?.strapiPage?.sections[2]
  const developmentService = data?.strapiPage?.sections[3]
  const develop = data?.strapiPage?.sections[4]
  const textRibbon = data?.strapiPage?.sections[5]
  const benefits = data?.strapiPage?.sections[6]
  const last = data?.strapiPage?.sections[7]
  const faq = data?.strapiPage?.sections[8]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner2 strapiData={banner} ruby={true} />
      <Talent strapiData={handPicked} />
      <Skillset strapiData={skillSet} />
      <Services strapiData={developmentService} />
      <Budget strapiData={develop} />
      <TextBtnStrip2 strapiData={textRibbon} />
      <Benefits strapiData={benefits} ruby={true} />
      <RubbySummary simage2={simage2} strapiData={last} />
      <Faqs strapiData={faq} />
    </MainLayout>
  )
}

export const query = graphql`
  query ruby {
    strapiPage(slug: { eq: "ruby-2" }) {
      sections {
        title

        cards {
          subTitle
          title
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            localFile {
              publicURL
            }
          }
          image2 {
            localFile {
              publicURL
            }
          }
          buttons {
            url
            title
          }
        }
        secImages {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default Ruby
