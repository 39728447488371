import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import simage2 from "../../../images/ruby/logofresh.svg"
import * as styles from "./RubbySummary.module.scss"

const RubbySummary = ({ strapiData }) => {
  return (
    <div className={`p-85 ${styles.bg}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <Row className="gap-30 my-5">
          <Col xs={12} md={12} lg={6}>
            <div>
              <img decoding="async" loading="lazy" src={simage2} alt="text" />
              <div
                className={styles.discription2}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <img
              decoding="async"
              loading="lazy"
              src={strapiData?.secImages[0].localFile.publicURL}
              alt="freshprep"
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default RubbySummary
