import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Talent.module.scss"

const Talent = ({ strapiData }) => {
  return (
    <div className={styles.subservice}>
      <Container>
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <Row>
          {strapiData?.cards &&
            strapiData?.cards?.map((e, i) => (
              <Col xs={12} lg={6} key={i}>
                <div className={styles.card}>
                  <img
                    decoding="async"
                    loading="lazy"
                    src={e?.image1[0]?.localFile?.publicURL}
                    alt={e?.title}
                  />
                  <h3>{e?.title}</h3>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default Talent
