import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Budget.module.scss"

const Budget = ({ strapiData }) => {
  return (
    <div className={styles.budget}>
      <Container>
        <div
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <Row className="gap-50 mt-5">
          {strapiData?.cards &&
            strapiData?.cards.map((e, i) => (
              <Col xs={12} md={6} lg={6} key={i}>
                <div className={styles.card}>
                  <img
                    decoding="async"
                    loading="lazy"
                    src={e?.image1[0].localFile.publicURL}
                    alt={e?.title}
                  />
                  <div>
                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.description?.description,
                      }}
                    />
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  )
}

export default Budget
